import React from 'react';
import logo from '../../images/inspire-insight-logo.svg';
import Footer from '../../components/Footer';
import SearchBar from '../../components/SearchBar';

const ScoreUpdates = () => {
  const quarters = [
    [2025, 2],
    [2025, 1],
    [2024, 4],
    [2024, 3],
    [2024, 2],
  ];

  const quarterMap: Record<number, string> = {
    [1]: 'January',
    [2]: 'April',
    [3]: 'July',
    [4]: 'October',
  };

  const files = quarters.map(([year, quarter]) => ({
    year,
    quarter,
    date: `${quarterMap[quarter]} 1, ${year}`,
    filename: `${year} Q${quarter} Score Updates.xlsx`,
  }));

  return (
    <>
      <div className="flex flex-col items-center px-4 md:px-10">
        <header className="flex justify-between items-center py-4 w-full flex-wrap">
          <a href="/"><img src={logo} className="w-40 order-1" alt="logo" /></a>
          <div className='basis-full md:basis-2/3 order-3 md:order-2'>
            <SearchBar />
          </div>
          <a href={process.env.REACT_APP_DASHBOARD_URL} className='order-2 md:order-3'>Dashboard</a>
        </header>
        <div className="flex flex-col items-center w-full max-w-screen-lg">
          <h1 className="text-xl font-bold mt-12 mb-2">Inspire Impact Score Update History</h1>
          <p className="text-center">The Inspire Impact Score is utilized by faith based investors around the world to inform investment decisions on billions of dollars of investment assets. We seek to keep Inspire Impact Scores up to date with fresh research and data on a regular basis. Below is a record of Inspire Impact Score updates and the dates which they became effective on inspireinsight.com.</p>
          <table className="mt-12 gap-4">
            <tr className="border-b-2">
              <th className="px-2 py-1 text-left">Effective Date</th>
              <th className="px-2 py-1 text-left">Score Updates</th>
            </tr>
            {files.map(file => (
              <tr key={`${file.year}Q${file.quarter}`} className="border-b border-blue">
                <td className="px-2 py-1">{file.date}</td>
                <td>
                  <a className="px-2 py-1 text-blue" href={`/assets/score-updates/${file.filename}`}>{file.filename}</a>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ScoreUpdates;
